import CheckBox from "./components/CheckBox";
import {
  IBrand,
  IData,
  IGlobalProduct,
  ILocation,
  IPmHier,
  IResPisTpsType,
} from "./types/data.interface";
import EditIcon from "./components/EditIcon";

export const currentYear = new Date().getFullYear();

export const mapYearOptions = () => {
  const years = [
    2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,
  ];
  const currentyear = new Date().getFullYear();
  return years
    .filter((year: number) => year <= currentyear)
    .map((year) => {
      return { value: year, label: year };
    });
};

export const editPageAcronyms: { [k: string]: string } = {
  dc: "/data-catalog",
  pmh: "/packing-material-hier",
  gpn: "/global-products",
  // tcc: "/tccs",
};

export const getPageAcronymByType = (type: string): string => {
  if (isDatacatalogType(type)) return "dc";
  if (isPackingType(type)) return "pmh";
  if (isGlobalProductType(type)) return "gpn";
  // if (isTccType(type)) return "tcc";
  return "";
};

export const getGpOrTccTypeByPageAcronym = (pageAcronym: string): string => {
  if (pageAcronym === "gpn") return "Global Product";
  // if (pageAcronym === "tcc") return "Tupperware Consumer Code";
  return "";
};

// constant data catalog type options
export const dataCatalogOptions = [
  { value: "Category", label: "Category" },
  { value: "Sub-Category", label: "Sub-Category" },
  { value: "Product Line", label: "Product Line" },
];

// constant gpn/tcc catalog type options
export const gpnTccCatalogOptions = [
  { value: "Global Product", label: "Global Product" },
  // { value: "Tupperware Consumer Code", label: "Tupperware Consumer Code" },
];

// constant packing material type options
export const pmHierOptions = [
  {
    value: "Packing Material Sub-Type",
    label: "Packing Material Sub-Type",
  },
  {
    value: "Packing Material Type",
    label: "Packing Material Type",
  },
];

export const getTypeOptionsBasedOnPermissions = (hasPermission: any) => {
  var options: { value: string; label: string }[] = [];
  if (hasPermission("api.view_datacatalog")) {
    options.push(...dataCatalogOptions);
    options.push(...gpnTccCatalogOptions);
  }

  if (hasPermission("api.view_packingmaterialhierarchy")) {
    options.push(...pmHierOptions);
  }
  return options;
};

// gets the parent type by type of child
export const getParentType = (type: string | null) => {
  if (type === "Sub-Category") return "Category";
  if (type === "Packing Material Sub-Type") return "Packing Material Type";
  // if (type === "Tupperware Consumer Code") return "Global Product";
  return "";
};

export const formatBooleanIntoOption = (item: boolean) => {
  return {
    value: item,
    label: item ? "Yes" : "No",
  };
};
export const formatBooleanOptions = () =>
  [true, false].map(formatBooleanIntoOption);

export const formatVendorIntoOption = (item: ILocation) => {
  return {
    value: item,
    label: `${item.location_id} - ${item.full_name}`,
  };
};
export const formatVendorOptions = (data: ILocation[]) =>
  data.map(formatVendorIntoOption);

export const formatTpsTypeIntoOption = (item: IResPisTpsType) => {
  return {
    value: item,
    label: `${item.res_pis_type_id} - ${item.name}`,
  };
};
export const formatTpsTypeOptions = (data: IResPisTpsType[]) =>
  data.map(formatTpsTypeIntoOption);

export const formatBrandIntoOption = (item: IBrand) => {
  return {
    value: item,
    label: item.name,
  };
};
export const formatBrandOptions = (data: IBrand[]) =>
  data.map(formatBrandIntoOption);

export const formatCapacityUnitIntoOption = (item: string) => {
  return {
    value: item,
    label: item,
  };
};
export const formatCapacityUnitOptions = () =>
  ["ML", "L", "CUP", "OZ", "QT"].map(formatCapacityUnitIntoOption);

export const formatSourcingTypeIntoOption = (item: string) => {
  return {
    value: item,
    label: item,
  };
};
export const formatSourcingTypeOptions = () =>
  ["TPS", "In-House", "Hybrid"].map(formatSourcingTypeIntoOption);

// export const formatMarketingMaterialIntoOption = (item: IMarketingMaterial) => {
//   return {
//     value: item,
//     label: item.name,
//   };
// };
// export const formatMarketingMaterialOptions = (data: IMarketingMaterial[]) =>
//   data.map(formatMarketingMaterialIntoOption);

// export const formatChannelIntoOption = (item: IChannel) => {
//   return {
//     value: item,
//     label: item.name,
//   };
// };
// export const formatChannelOptions = (data: IChannel[]) =>
//   data.map(formatChannelIntoOption);

// export const formatLicensorIntoOption = (item: ILicensor) => {
//   return {
//     value: item,
//     label: item.name,
//   };
// };
// export const formatLicensorOptions = (data: ILicensor[]) =>
//   data.map(formatLicensorIntoOption);

// export const formatLicensedPropertyIntoOption = (item: ILicensedProperty) => {
//   return {
//     value: item,
//     label: item.name,
//   };
// };
// export const formatLicensedPropertyOptions = (data: ILicensedProperty[]) =>
//   data.map(formatLicensedPropertyIntoOption);

export const formatIsRoyaltyIntoOption = (item: boolean) => {
  return {
    value: item,
    label: item ? "Yes" : "No",
  };
};
export const formatIsRoyaltyOptions = () =>
  [true, false].map(formatIsRoyaltyIntoOption);

// export const formatFlavorScentIntoOption = (item: IFlavorScent) => {
//   return {
//     value: item,
//     label: item.name,
//   };
// };
// export const formatFlavorScentOptions = (data: IFlavorScent[]) =>
//   data.map(formatFlavorScentIntoOption);

export const formatLifecycleStatusIntoOption = (item: string) => {
  return {
    value: item,
    label: item,
  };
};
export const formatLifecycleStatusOptions = () =>
  ["Active", "Inactive"].map(formatLifecycleStatusIntoOption);

// export const formatProductColorIntoOption = (item: IColor) => {
//   return {
//     value: item,
//     label: `${item.id} - ${item.name}`,
//   };
// };
// export const formatProductColorOptions = (data: IColor[]) =>
//   data.map(formatProductColorIntoOption);

// export const formatArtworkIntoOption = (item: IArtwork) => {
//   return {
//     value: item,
//     label: `${item.id} - ${item.name}`,
//   };
// };
// export const formatArtworkOptions = (data: IArtwork[]) =>
//   data.map(formatArtworkIntoOption);

export const formatDatacalogIntoOption = (item: IData) => {
  return {
    value: item,
    label: `${item.code ? item.code : "(no code)"} - ${item.name} ${
      item.active ? "" : "(inactive)"
    }`,
  };
};
export const formatDatacatalogOptions = (data: IData[]) =>
  data.map(formatDatacalogIntoOption);

export const formatPmHierIntoOption = (item: IPmHier) => {
  return {
    value: item,
    label: `${item.code} - ${item.name} ${item.active ? "" : "(inactive)"}`,
  };
};
export const formatPmHierOptions = (data: IPmHier[]) =>
  data.map(formatPmHierIntoOption);

export const formatGlobalProductIntoOption = (item: IGlobalProduct) => {
  return {
    value: item,
    label: getGlobalProductCode(item) + " - " + item.name,
  };
};
export const formatGlobalProductOptions = (data: IGlobalProduct[]) =>
  data.map(formatGlobalProductIntoOption);

// export const formatTccIntoOption = (item: ITcc) => {
//   return {
//     value: item,
//     label: getTccCode(item) + " - " + item.name,
//   };
// };
// export const formatTccsOptions = (data: ITcc[]) =>
//   data.map(formatTccIntoOption);

export const getGlobalProductCode = (gpn: IGlobalProduct | null): string => {
  if (!gpn) return "";
  let code: string = `${gpn.sub_category?.parent?.code ?? ""}${
    gpn.sub_category?.code ?? ""
  }${gpn.product_line?.code ?? ""}${gpn.suffix}`;
  return code;
};

// export const getTccCode = (tcc: ITcc | null): string => {
//   if (!tcc) return "";
//   let code: string = `${getGlobalProductCode(tcc.global_product)}-${
//     tcc.suffix
//   }`;
//   return code;
// };

export const isPackingType = (type: string) =>
  pmHierOptions.map((option) => option.value).includes(type);

export const isDatacatalogType = (type: string) =>
  dataCatalogOptions.map((option) => option.value).includes(type);

export const isGpnTccCatalogType = (type: string) => isGlobalProductType(type);
// || isTccType(type);

export const isGlobalProductType = (type: string) => type === "Global Product";

// export const isTccType = (type: string) => type === "Tupperware Consumer Code";

export const addType = (item: any, type: string) => {
  if (isGpnTccCatalogType(type)) {
    return { ...item, type: type };
  } else {
    return item;
  }
};

export const fillItemRelatedObjects = (item: any, combData: any[]) => {
  if (
    (isDatacatalogType(item.type) || isPackingType(item.type)) &&
    item.parent
  ) {
    return {
      ...item,
      parent: combData.find(
        (data: any) =>
          data.type === getParentType(item.type) && data.id === item.parent
      ),
    };
  }
  if (
    isGlobalProductType(item.type) &&
    (item.sub_category || item.product_line)
  ) {
    return {
      ...item,
      sub_category: item.sub_category
        ? combData.find(
            (data: any) =>
              data.type === "Sub-Category" && data.id === item.sub_category
          )
        : null,
      product_line: item.product_line
        ? combData.find(
            (data: any) =>
              data.type === "Product Line" && data.id === item.product_line
          )
        : null,
    };
  }
  // if (isTccType(item.type) && item.global_product) {
  //   return {
  //     ...item,
  //     parent: combData.find(
  //       (data: any) =>
  //         data.type === getParentType(item.type) &&
  //         data.id === item.global_product
  //     ),
  //   };
  // }

  return item;
};

export const getGenericEmptyObject = () => {
  return {
    name: "",
    type: "",
    parent: null,
    acronym: "",
    active: true,
    code: 0,
    suffix: "",
    global_product: null,
    sub_category: null,
    product_line: null,
  };
};

export const buildDatacatalogColumnDefs = (
  specificType: string,
  hasPermission: any
) => {
  let columnDefs: any[] = [
    { field: "code", maxWidth: 90, sort: "asc" as const },
    { field: "name", headerName: "Value", flex: 1 },
  ];

  if (getParentType(specificType)) {
    columnDefs.push(
      {
        headerName: "Category Code",
        field: "parent.code",
        maxWidth: 170,
      },
      {
        headerName: "Category Value",
        field: "parent.name",
        flex: 1,
      }
    );
  }

  columnDefs.push({
    field: "active",
    headerName: "Active?",
    cellRenderer: CheckBox,
    minWidth: 110,
    maxWidth: 120,
    wrapText: false,
  });

  if (hasPermission("api.change_datacatalog")) {
    columnDefs.push({
      field: "Actions",
      cellRenderer: EditIcon,
      maxWidth: 120,
      wrapText: false,
      sortable: false,
    });
  }

  return columnDefs;
};

export const buildGpnTccColumnDefs = (
  specificType: string,
  hasPermission: any
) => {
  let columnDefs: any[] = [
    {
      headerName: "Code",
      valueGetter: (params: any) => getGlobalProductCode(params.data),
      // ? getTccCode(params.data)
      maxWidth: 180,
      sort: "asc" as const,
    },
    { field: "name", headerName: "Name", flex: 2 },
  ];

  if (getParentType(specificType)) {
    columnDefs.push({
      headerName: "GP Name",
      field: "global_product.name",
      flex: 1,
    });
  }

  columnDefs.push(
    {
      headerName: "Category",
      valueGetter: (params: any) => {
        const gpn = getParentType(specificType)
          ? params.data.global_product
          : params.data;
        return gpn
          ? formatDatacalogIntoOption(gpn.sub_category.parent).label
          : "";
      },
      flex: 1,
    },
    {
      headerName: "Sub-Category",
      valueGetter: (params: any) => {
        const gpn = getParentType(specificType)
          ? params.data.global_product
          : params.data;
        return gpn ? formatDatacalogIntoOption(gpn.sub_category).label : "";
      },
      flex: 1,
    },
    {
      headerName: "Product Line",
      valueGetter: (params: any) => {
        const gpn = getParentType(specificType)
          ? params.data.global_product
          : params.data;
        return gpn ? formatDatacalogIntoOption(gpn.product_line).label : "";
      },
      flex: 1,
    }
  );

  if (hasPermission("api.change_datacatalog")) {
    columnDefs.push({
      field: "Actions",
      cellRenderer: EditIcon,
      maxWidth: 120,
      wrapText: false,
      sortable: false,
    });
  }

  return columnDefs;
};

export const buildPmHierColumnDefs = (
  specificType: string,
  hasPermission: any
) => {
  let columnDefs: any[] = [
    {
      headerName: "Acronym",
      field: "code",
      maxWidth: 120,
      sort: "asc" as const,
    },
    { field: "name", headerName: "Value", flex: 1 },
  ];

  if (getParentType(specificType)) {
    columnDefs.push(
      {
        headerName: "Packing Material Type Acronym",
        field: "parent.code",
      },
      {
        headerName: "Packing Material Type Value",
        field: "parent.name",
        flex: 1,
      }
    );
  }

  columnDefs.push({
    field: "active",
    headerName: "Active?",
    cellRenderer: CheckBox,
    minWidth: 110,
    maxWidth: 120,
    wrapText: false,
  });

  if (hasPermission("api.change_packingmaterialhierarchy")) {
    columnDefs.push({
      field: "Actions",
      cellRenderer: EditIcon,
      maxWidth: 120,
      wrapText: false,
      sortable: false,
    });
  }

  return columnDefs;
};

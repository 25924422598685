import OptionFormatter from "../../OptionFormatter";
import { useEffect, useState } from "react";
import { IGlobalProduct } from "../../../types/data.interface";
import { formatGlobalProductOptions } from "../../../helpers";
import AsyncSelect from "react-select/async";

export const GpnFilterForm = ({
  globalProducts,
  typeSelected,
  setFilteredValues,
}: {
  globalProducts: IGlobalProduct[];
  typeSelected: string;
  setFilteredValues: any;
}) => {
  const [gpnValue, setGpnValue] = useState<{
    label: string;
    value: IGlobalProduct;
  } | null>(null);

  const [gpnValueOptions, setGpnValueOptions] = useState<
    {
      label: string;
      value: IGlobalProduct;
    }[]
  >();

  useEffect(() => {
    setGpnValue(null);
    setGpnValueOptions(formatGlobalProductOptions(globalProducts));
  }, [typeSelected, globalProducts]);

  useEffect(() => {
    let filteredValues: IGlobalProduct[] = globalProducts;
    if (gpnValue) {
      filteredValues = filteredValues.filter(
        (item) => item.id === gpnValue.value.id
      );
    }

    setFilteredValues(filteredValues);
  }, [gpnValue]);

  const handleGpnValue = (e: any) => {
    if (e) {
      setGpnValue(e);
    } else {
      setGpnValue(null);
    }
  };

  const loadGpsBasedOnSearch = (input: string, callback: any) => {
    if (input.length < 3) {
      return callback([]);
    }

    const optionsToSearchFrom = gpnValueOptions;
    const optionsFound =
      optionsToSearchFrom
        ?.filter((gpOption) =>
          gpOption.label.toLowerCase().includes(input.toLowerCase())
        )
        .slice(0, 100) ?? [];
    callback(optionsFound);
  };

  return (
    <>
      <div className="row form-row align-items-end">
        <div className="col-md-10 form-group">
          <label className="form-label">Code / Name</label>
          <AsyncSelect
            onChange={handleGpnValue}
            defaultOptions={!gpnValue ? [] : [gpnValue]}
            loadOptions={loadGpsBasedOnSearch}
            noOptionsMessage={() => (
              <p className="mb-0">
                Search for the Code or Name of the desired Global Product
              </p>
            )}
            value={gpnValue}
            classNamePrefix="react-select"
            placeholder="(min 3 characters)"
            isClearable
            formatOptionLabel={OptionFormatter}
            isSearchable
            isLoading={gpnValueOptions?.length == 0}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>
    </>
  );
};

import { useState, useEffect, useContext } from "react";
import GPHTable from "../components/GPHTable";
import GPHFilters from "../components/GPHFilters";
import { Link, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../context/AuthContext";

const GlobalProductHierarchyPage = () => {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const {
    user,
    dataCatalog,
    pmhier,
    globalProducts,
    // tccs,
    isLoading,
    setIsLoading,
  } = useOutletContext<any>();
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [typeSelected, setTypeSelected] = useState<string>("");
  const [isFirstSearch, setIsFirstSearch] = useState<boolean>(true);

  const handleTableFilter = (typeSelected: string, filteredData: any[]) => {
    setTypeSelected(typeSelected);
    setFilteredData(filteredData);
    setIsFirstSearch(false);
  };

  useEffect(() => {
    if (user) {
      setIsLoading(isLoading);
    }
  }, [user, isLoading]);

  return (
    <div>
      <GPHFilters
        data={[...dataCatalog, ...pmhier]}
        gps={globalProducts}
        // tccs={tccs}
        isLoading={isLoading}
        handleTableFilter={handleTableFilter}
      />
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 text-primary">Search Results</h6>
        </div>
        <div className="card-body">
          <GPHTable
            data={filteredData}
            tableType={typeSelected}
            isFirstSearch={isFirstSearch}
          />

          {hasPermission("api.add_datacatalog") ||
          hasPermission("api.add_packingmaterialhierarchy") ? (
            <Link to="create-new" className="btn btn-primary">
              Add
            </Link>
          ) : (
            <div className="info-box">
              <FontAwesomeIcon icon={faCircleInfo} className="ml-1 mr-3" />
              <div>
                {hasPermission("api.view_datacatalog") ? (
                  <p className="mb-0">
                    If a Category, Sub-Category or Product Line is missing,
                    please contact Sophie Wolters or Monica Dominguez to create
                    it.
                  </p>
                ) : null}
                {hasPermission("api.view_packingmaterialhierarchy") ? (
                  <p className="mb-0">
                    If a Packing Material Type or Sub-Type is missing, please
                    contact Carolina Vega to create it.
                  </p>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalProductHierarchyPage;
